import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from './../components/Layout';
import Content, { HTMLContent } from './../components/Content';
import { Typography, Card, Col, Row } from 'antd';

const { Title, Paragraph } = Typography;

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <Row gutter={24}>
      {helmet || ''}
      <Col 
        lg={{
          span: 18,
          offset: 3
        }}
        md={{
          span: 18,
          offset: 3
        }}
        sm={{
          span: 24,
          offset: 0
        }}
        xs={{
          span: 24,
          offset: 0
        }}
        >
        <Card>
          <Title style={{
            color: '#ababab'
          }}>
            {title}
          </Title>
          <Paragraph level={4} style={{
            color: '#ababab',
            marginBottom: '40px'
          }}>{description}</Paragraph>
          <PostContent content={content} />

          {tags && tags.length ? (
            <div style={{ marginTop: `4rem` }}>
              <h4>Tags</h4>
              <ul className="taglist">
                {tags.map(tag => (
                  <li key={tag + `tag`}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </Card>
      </Col>
    </Row>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | CodePanion">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <link rel="canonical" href={`https://codepanion.com${post.fields.slug}`} />

            <meta name="author" content="Stefan Mitrovic" />
            <meta property="og:locale" content="en" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="og:description" content={post.frontmatter.description} />
            <meta property="og:url" content={`https://codepanion.com${post.fields.slug}`} />
            <meta property="og:site_name" content={post.frontmatter.title} />
            <meta
              property="og:image"
              content={`https://codepanion.com${post.frontmatter.featuredimage.childImageSharp.fluid.src}`}
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:description" content={post.frontmatter.description} />
            <meta name="twitter:title" content={post.frontmatter.title} />
            <meta name="twitter:image" content={`https://codepanion.com${post.frontmatter.featuredimage.childImageSharp.fluid.src}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 360, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
